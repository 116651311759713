import { getAccountId } from './common';
import getAxios from './common/header';
import { directoryCode } from './common/directoryCode';
import { getApi } from './common/apis';

export default class ProfileService {
    async getProfile() {
        var profileData = await getApi('v1/partner-profile');
        return profileData;
    }

    async getProfileById() {
        const accountId = getAccountId();
        var profileData = await getAxios().get(
            `${process.env.VUE_APP_ANSWER_BANK_SERVICE_URL}v2/answer-bank?id=${accountId}&qsid=${directoryCode.PARTNER}`
        );
        return profileData;
    }

    async updateProfile(payload) {
        const accountId = getAccountId();
        var profileData = await getAxios().post(
            `${process.env.VUE_APP_ANSWER_BANK_SERVICE_URL}v2/answer-bank?id=${accountId}&qsid=${directoryCode.PARTNER}`,
            JSON.parse(JSON.stringify(payload))
        );
        return profileData;
    }
}
